import React from "react"

import Layout from "components/site-structure/layout"
import SEOComponent from "components/seo"

const News = () => (
  <Layout>
    <SEOComponent
      description="Hier findest Du alle Neueringen und Updates am Mölkky Online Scoreboard"
      titel="Updates & News"
    />

    <div className="container">
      <h1 className="title is-1">Updates und News</h1>
    </div>
  </Layout>
)

export default News
